
import { createMedia } from '@artsy/fresnel'
import React, { useState, useEffect } from 'react'
import { InView } from 'react-intersection-observer'
import { useForm } from '@formspree/react';

import logo from './logo.svg';
import './App.css';
import customerLogo from './customer.svg'
import riderLogo from './rider.svg'
import ngnLogo from './ngn.svg'
import thelimaRiderLogo from './rider_thelima_logo.svg'
import thelimaDarkLogo from './thelimaDarkLogo.svg'
import thelimaLightLogo from './thelimaLightLogo.svg'
import heroMobile from './mobile.svg'
import errandFeature from './errand_feature.svg'
import priceFeature from './price_feature.svg'
import priceLinker from './price_linker.svg'
import rewardFeature from './reward_feature.svg'
import rewardLinker from './reward_linker.svg'
import PlayButton from './google_play_button.svg'
import StoreButton from './app_store_button.svg'
import mobileRider from './mobile_rider.svg'
import earningFeature from './earnings_features.svg'
import earningLinker from './earningLinker.svg'
import costFeature from './costFeature.svg'
import costLinker from './costLinker.svg'
import aiFeature from './aiFeature_4.svg'
import mobile_logo_customer from './mobile_logo_customer.svg'
import mobile_logo_rider from './mobile_logo_rider.svg'
/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */

import {
  Dropdown,
  Button,
  Container,
  Grid,
  Header,
  Image,
  Accordion,
  Menu,
  Segment,
  Sidebar,
  Divider,
  Form,
  SegmentGroup,
  Icon
} from 'semantic-ui-react'

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
})

const userTypes = [
  {
    key: 'customer',
    text: 'Customers',
    value: 'customer',
    image: { avatar: true, fluid: true, src: customerLogo }
  },
  {
    key: 'rider',
    text: 'Riders',
    value: 'rider',
    image: { avatar: true, fluid: true, src: riderLogo }
  },
  {
    key: 'developer',
    text: 'Developers',
    value: 'developer',
    image: { avatar: true, fluid: true, src: riderLogo }
  },
]


const faqs = [
  {
    key: 'what-is-thelima',
    title: 'What is Thelima?',
    content: [
      ' ',
    ].join(' '),
  },
  {
    key: 'how-to',
    title: 'How do I request for a Thelima Driver?',
    content: [
      ' .',
    ].join(' '),
  },
  {
    key: 'how-to-driver',
    title: 'How can I become a Thelima Driver?',
    content: {
      content: (
        <div>
          <p>
            .
          </p>
          <p>
            A.
          </p>
        </div>
      ),
    },
  },
]


function NavigationBar({ theme, handleUserChange }) {
  return (
    <Grid
      columns='equal'
      className={`navigation-bar ${theme ? 'light' : 'dark'}`}>
      <Grid.Column>
        <Image
          src={theme ? thelimaDarkLogo : thelimaRiderLogo}
          className='navigation-logo'
        />
      </Grid.Column>
      <Grid.Column>
        <Menu
          secondary
          container
          className={`navigation-menu ${theme ? 'light' : 'dark'}`}
        >
          <Menu.Item
            fluid
            href="#header">
            Home
          </Menu.Item>
          <Menu.Item
            fluid
            href="#features">
            Features
          </Menu.Item>
          <Menu.Item
            fluid
            href='mailto:admin@thelima.app'>
            Contact
          </Menu.Item>
        </Menu>
      </Grid.Column>
      <Grid.Column>
        <Menu
          className={`navigation-button ${theme ? 'light' : 'dark'}`}
          secondary
          container>
          <Menu.Item>
            <Dropdown
              selection
              defaultValue={userTypes[0].value}
              options={userTypes}
              onChange={handleUserChange}
            /></Menu.Item>
        </Menu>
      </Grid.Column>
    </Grid>
  )
}


function WaitListInput() {

  const [state, handleSubmit] = useForm(process.env.REACT_APP_FORM_ID);

  const [buttonContent, setButtonContent] = useState("Join Waitlist")

  useEffect(() => {
      if (state.succeeded){
        setButtonContent('Enlisted')
      }
  }, [state.succeeded])

  return (
    <Segment as={Form} vertical basic className='hero-input' loading={state.submitting} onSubmit={handleSubmit}>
      <Form.Group>
        <Form.Input name="email" transparent placeholder='Your email...' error={state?.errors?.getFieldErrors('email')[0]?.message} disabled={state.succeeded}/>
        <Form.Button positive type="submit" content={buttonContent} icon={state.succeeded ? 'check': 'right arrow'} labelPosition='right'  disabled={state.submitting || state.submitting}/>
        </Form.Group>
    </Segment>
  )
}

function RiderHeader() {
  return (
    <Segment horizontal
      id='header'
      className='hero-section-rider dark'>
      <Grid columns={2} stackable textAlign='center'>
        <Grid.Row verticalAlign='middle'>
          <Grid.Column className='hero-message-rider'>
            <Header fluid as='h2' centered>
              <Header.Content>
                Become a Dispatch & <br></br>Delivery Hero
                <Header.Subheader>Enjoy flexibility, and make each dispatch a success. Earn as you drive, <br></br>become a Thelima Hero and drive with purpose. Coming Soon!</Header.Subheader>
              </Header.Content>
            </Header>
            <WaitListInput />
          </Grid.Column>
          <Grid.Column>
            <Segment basic >
              <Image centered src={mobileRider} />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  )
}
/* 
 * Header for
 */
function CustomerHeader() {
  return (
    <Segment.Group id='header' className='hero-section-customer light'>
      <Segment horizontal basic className='hero-message'>
        <Header as='h2'>
          <Header.Content>
            Dispatch & Delivery  <br></br> Service Made Seamless
            <Header.Subheader>Experience the ease of dispatching and delivering errands with Thelima. Seamlessly send, pick-up and manage errands with our reliable service tailored for you. Same day delivery guaranteed</Header.Subheader>
          </Header.Content>
        </Header>
      </Segment>
      <WaitListInput />
      <Segment basic className='hero-picture'>
        <Image centered src={heroMobile} />
      </Segment>
    </Segment.Group>
  )
}

function RiderFeatureSection() {
  return (
    <Segment id="features" raised basic className='features-section' vertical>
      <Grid fluid verticalAlign='middle'>
        <Grid.Row centered as='h1'>
          Features
        </Grid.Row>
        <Grid.Row container columns={2}>
          <Image className='linker' centered src={earningLinker} />
          <Grid.Column floated='left' textAlign='left'>
            <Image className='leftImage' src={earningFeature} />
          </Grid.Column>
          <Grid.Column floated='right' className='rightText'>
            <Header floated='right' as='h2'>
              <Header.Content>
                Maximize your earnings
                <Header.Subheader>View your earnings, see how far you have gone as a hero.<br></br>Withdraw your earnings within minutes.</Header.Subheader>
              </Header.Content>
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row container columns={2}>
          <Image className='linker' centered src={costLinker} />
          <Grid.Column className='leftText' textAlign='left' verticalAlign='top' floated='right'>
            <Header as='h2'>
              <Header.Content>
                Negotiate the cost
                <Header.Subheader>Negotiate the cost for each errand request within an
                  acceptable range. <br></br> It's a win-win.</Header.Subheader>
              </Header.Content>
            </Header>
          </Grid.Column>
          <Grid.Column floated='right' verticalAlign='top'>
            <Image floated='right' className='rightImage' src={costFeature} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row container columns={2}>
          <Grid.Column floated='left' textAlign='left'>
            <Image className='leftImage' src={errandFeature} />
          </Grid.Column>
          <Grid.Column floated='right' className='rightText'>
            <Header floated='right' as='h2'>
              <Header.Content>
                Accept multiple errands
                <Header.Subheader>Take multiple errands that are seamless and easy for you. <br></br>Manage your requests, earn more and drive with success.</Header.Subheader>
              </Header.Content>
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row container columns={2}>
          <Grid.Column className='leftText' textAlign='left' verticalAlign='top'>
            <Header as='h2'>
              <Header.Content>
                AI-suggested routes
                <Header.Subheader>With our AI, get personalized suggested routes to plan the day. <br></br>Maximize your day while you earn more.</Header.Subheader>
              </Header.Content>
            </Header>
          </Grid.Column>
          <Grid.Column floated='right' verticalAlign='top'>
            <Image className='rightImage' floated='right' src={aiFeature} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  )
}

function CustomerFeatureSection() {
  return (
    <Segment raised basic className='features-section' id="features" vertical>
      <Grid fluid verticalAlign='middle'>
        <Grid.Row centered as='h1'>
          Features
        </Grid.Row>
        <Grid.Row container columns={2}>
          <Grid.Column className='leftText'>
            <Header as='h2'>
              <Header.Content>
                A dispatch errand <br></br>with ease
                <Header.Subheader>Easily place a request to send or pick-up items (food, grocery, etc) from the <br></br>comfort of your home in easy steps.</Header.Subheader>
              </Header.Content>
            </Header>
          </Grid.Column>
          <Grid.Column textAlign='right'>
            <Image floated='right' className='rightImage' src={errandFeature} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row container columns={2}>
          <Image className='linker' centered src={priceLinker} />
          <Grid.Column floated='left' textAlign='left'>
            <Image className='leftImage' src={priceFeature} />
          </Grid.Column>
          <Grid.Column className='rightText' floated='right'>
            <Header floated='right' as='h2'>
              <Header.Content>
                Set the price
                <Header.Subheader>Set the price for each errand from within an acceptable range.<br></br>Prices are negotiable between you and the driver.</Header.Subheader>
              </Header.Content>
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row container columns={2}>
          <Image className='linker' centered src={rewardLinker} />
          <Grid.Column className='leftText' textAlign='left' verticalAlign='top'>
            <Header as='h2'>
              <Header.Content>
                Get point rewards
                <Header.Subheader>Get rewarded for every successful errand made using your wallet or card. <br></br>Accumulated points can be used to offset the next request.</Header.Subheader>
              </Header.Content>
            </Header>
          </Grid.Column>
          <Grid.Column floated='right' verticalAlign='top'>
            <Image floated='right' className='rightImage' src={rewardFeature} />
          </Grid.Column>
        </Grid.Row>


      </Grid>

    </Segment>
  )
}

function Body({ userType }) {

  const [buttonState, setButtonState] = useState(false)

  const handleClick = () => {
    if(!buttonState){
      setButtonState(true)
    }
  }
  
  return (
    <Segment style={{ 'padding': 0 }}>
      {userType === 'customer' && <CustomerFeatureSection />}
      {userType === 'rider' && <RiderFeatureSection />}
      {['rider', 'customer'].includes(userType) &&
        <Segment basic className='faqs-section' vertical>
          <Grid fluid verticalAlign='middle'>
            <Grid.Row container centered>
              <Segment basic textAlign='center' className='hero-message'>
                <Header as='h2'>
                  <Header.Content>
                    Frequently asked questions
                    <Header.Subheader>Still need more information and answers you are looking for? Kindly email our support team <a href='mailto:admin@thelima.app'>admin@thelima.app</a>.</Header.Subheader>
                  </Header.Content>
                </Header>
              </Segment>
            </Grid.Row>
            <Grid.Row container textAlign='left'>
              <Container>
                <Accordion fluid styled defaultActiveIndex={0} panels={faqs} />
              </Container>
            </Grid.Row>
          </Grid>
        </Segment>}

      <Segment centered basic className={`announcement-section ${userType === 'customer' ? '' : 'rider'}`}>
        <Header textAlign='center' as='h2' onClick={ e=> setButtonState(false)}>
          <Header.Content>
            Coming Soon!
            <Header.Subheader>
              {userType === 'customer' && 'Experience seamless service on dispatch and delivery errands.'}
              {userType === 'rider' && 'Enjoy flexibility, earn while you drive and become a Thelima Hero.'}
              {userType === 'developer' && 'Seamlessly integrate Thelima in your eCommerce platform to handle delivery for your customers.'}
              {' Join the waitlist to have early access when we launch.'}
            </Header.Subheader>
          </Header.Content>
        </Header>
        {buttonState? <WaitListInput /> : <Button positive content='Join Waitlist' icon='right arrow' labelPosition='right' onClick={handleClick} /> }
      </Segment>

      <footer className='footer-section'>
        <Segment>
          <Grid >
            {['customer', 'developer'].includes(userType) ?
              <Grid.Row verticalAlign='bottom' columns={2}>
                <Grid.Column>
                  <Image className='footer-logo' src={thelimaLightLogo} />
                </Grid.Column>
                <Grid.Column verticalAlign='bottom' textAlign='right'>
                  <Segment horizontal>
                    <Menu
                      secondary
                      className='footer-menu'
                    >
                      <Menu.Item
                        position='right'
                        href="#features">
                        Features
                      </Menu.Item>
                      <Menu.Item
                        fluid>
                        Terms
                      </Menu.Item>
                      <Menu.Item
                        fluid>
                        Privacy
                      </Menu.Item>
                      <Menu.Item
                        fluid
                        href='mailto:admin@thelima.app'>
                        Contact
                      </Menu.Item>
                    </Menu>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
              :
              <Grid.Row centered>
                <Grid centered>
                  <Grid.Row centered>
                    <Image centered className='footer-logo rider' src={thelimaRiderLogo} />
                  </Grid.Row>
                  <Grid.Row centered>
                    <Menu
                      secondary
                      className='footer-menu'
                    >
                      <Menu.Item
                        href="#features">
                        Features
                      </Menu.Item>
                      <Menu.Item
                        fluid>
                        Terms
                      </Menu.Item>
                      <Menu.Item
                        fluid>
                        Privacy
                      </Menu.Item>
                      <Menu.Item
                        fluid
                        href='mailto:admin@thelima.app'>
                        Contact
                      </Menu.Item>
                    </Menu>
                  </Grid.Row>
                </Grid>
              </Grid.Row>

            }
            <Divider section />
            <Grid.Row verticalAlign='bottom' centered columns={3}>
              <Grid.Column text>
                @ {new Date().getFullYear()} Thelima, All rights reserved
              </Grid.Column>
              <Grid.Column textAlign='center' className='soon'>
                Available soon
              </Grid.Column>
              <Grid.Column basic horizontal textAlign='right' >
                <Image.Group content>
                  <Image fluid size='small' src={PlayButton} />
                  <Image fluid size='small' src={StoreButton} />
                </Image.Group>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

      </footer>
    </Segment>
  )
}
/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
function DesktopContainer({ children }) {
  const [userType, setUserType] = useState('customer');
  const [fixed, toggleFixedMenu] = useState(false)

  const toggleUserType = (e, { name, value }) => setUserType(value);

  return (
    <Media greaterThan='mobile'>
      <InView onChange={toggleFixedMenu}>
        <NavigationBar theme={userType === 'customer' || userType === 'developer'} handleUserChange={toggleUserType} />
        {userType === 'customer' && <CustomerHeader />}
        {userType === 'rider' && <RiderHeader />}
      </InView>
      <Body userType={userType} />
    </Media>
  )
}

function MobileContainer({ children }) {
  const [visible, setVisible] = useState(false)
  const [userType, setUserType] = useState('customer');
  const bodyRef = React.useRef()

  const toggleUserType = (e, { name, value }) => setUserType(value);
  const theme= userType === 'customer' || userType === 'developer'

  return (
    <Media as={Sidebar.Pushable} at='mobile'>
      <Sidebar.Pushable as={SegmentGroup}>
        <Sidebar
          as={Menu}
          animation='overlay'
          inverted
          icon='labeled'
          // target={bodyRef}
          onHide={() => setVisible(false)}
          vertical
          visible={visible}
          width='thin'
        >
          {/* <Container> */}
         
          <Menu.Item
            href="#header">
            Home
          </Menu.Item>
          <Menu.Item
            href="#features">
            Features
          </Menu.Item>
          <Menu.Item
            href='mailto:admin@thelima.app'>
            Contact
          </Menu.Item>
        </Sidebar>
        <Sidebar.Pusher dimmed={visible}>

        <Grid
      columns='equal'
      className={`mobile-navigation-bar ${theme ? 'light' : 'dark'}`}>
      <Grid.Column>
        <Image
          src={theme ? mobile_logo_customer : mobile_logo_rider}
        />
      </Grid.Column>
      <Grid.Column verticalAlign='middle'>
        <Menu>
          <Menu.Item>
            <Dropdown
              selection
              
              defaultValue={userTypes[0].value}
              options={userTypes}
              onChange={toggleUserType}
            /></Menu.Item>
            </Menu>
        <Button content as={Icon} icon='sidebar' size='large' onClick={(e) => setVisible(!visible)}/>
      </Grid.Column>
      </Grid>
      <Body ref={bodyRef} userType={userType} />
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </Media>
  )
}

const ResponsiveContainer = ({ children }) => (
  /* Heads up!
   * For large applications it may not be best option to put all page into these containers at
   * they will be rendered twice for SSR.
   */
  <MediaContextProvider>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </MediaContextProvider>
)

const HomepageLayout = () => (
  <ResponsiveContainer />
)

export default HomepageLayout